import useTranslation from 'next-translate/useTranslation';
import { forwardRef, useState } from 'react';
import { useTheme } from 'styled-components';

import {
  Box,
  ButtonProps,
  Icon,
  Image,
  LinkTranslatedStyledAsLink,
  Paper,
  StyledButton,
  Typography,
} from '@core/component';
import { Translate, metalToSlug } from '@core/constant';
import { useContextMediaQuery } from '@core/context';
import { useLanguage } from '@core/hook';
import { EFilenameBlur, getStaticPathImage, getStaticPathImageBlurData } from '@core/media';
import {
  EActionAttribute,
  EColor,
  EFontWeight,
  EIcon,
  EMetal,
  ERouting,
  ESize,
  ThemeProps,
} from '@core/type';

export const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    structure: {
      button: {
        icon: { indent },
      },
    },
  } = useTheme() as ThemeProps;

  const {
    text,
    isLoading,
    hiddenIcon,
    colorIcon,
    endIcon,
    startIcon,
    zIndex,
    children,
    iconOrientation,
    lineHeight,
    ...restProps
  } = props;

  const hasIcon = !!startIcon || !!endIcon;

  return (
    <StyledButton
      {...restProps}
      zIndex={zIndex}
      hasIcon={hasIcon}
      ref={ref}
      colorIcon={colorIcon}
      padding={'0 16px'}
      canOverrideTypography
    >
      <Typography
        as={EActionAttribute.SPAN}
        lineHeight={'34px'}
        fontSize={'15px'}
        fontWeight={EFontWeight.MEDIUM}
      >
        {children}
      </Typography>
      {endIcon && (
        <Icon
          type={endIcon}
          marginLeft={indent}
          isLoading={isLoading}
          color={colorIcon}
          hidden={hiddenIcon}
          orientation={iconOrientation}
        />
      )}
    </StyledButton>
  );
});

Button.displayName = 'Button';

const ImageMetals = ({ isHover }: { isHover: boolean }): JSX.Element => (
  <>
    <Box
      position={'absolute'}
      top={'-35px'}
      left={'0'}
      transform={isHover ? 'rotate(60deg)' : 'rotate(15deg)'}
      transition={'0.5s'}
    >
      <Image
        src={getStaticPathImage('shop/precious-metal-gold.png')}
        blurDataURL={getStaticPathImageBlurData(EFilenameBlur['shop/precious-metal-gold.png'])}
        height={'75'}
        width={'75'}
        alt={'Precious metal gold'}
        priority
      />
    </Box>
    <Box
      position={'absolute'}
      top={'-23px'}
      left={'56px'}
      transform={isHover ? 'rotate(30deg)' : 'rotate(-15deg)'}
      transition={'0.5s'}
    >
      <Image
        src={getStaticPathImage('shop/precious-metal-silver.png')}
        blurDataURL={getStaticPathImageBlurData(EFilenameBlur['shop/precious-metal-silver.png'])}
        height={'100'}
        width={'100'}
        alt={'Precious metal silver'}
        priority
      />
    </Box>
  </>
);

export const InvestmentAssistantBanner = ({ metal }: { metal?: EMetal }) => {
  const { isPhone, isMobile } = useContextMediaQuery();
  const { language } = useLanguage();

  const { t: tWidget } = useTranslation(Translate.common.WIDGET);

  const [isHover, setIsHover] = useState<boolean>(false);

  return (
    <Box maxWidth={isMobile ? '375px' : '738px'} margin={'0 auto'}>
      <LinkTranslatedStyledAsLink
        keyRoute={ERouting.SAVING_ASSISTANT}
        query={{ category: metalToSlug[language][metal] }}
      >
        <Paper
          backgroundColor={EColor.PRIMARY}
          overflow={'hidden'}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          <Box
            position={'relative'}
            padding={isMobile ? '24px 24px' : '12px 24px'}
            backgroundImage={
              isMobile
                ? `url(${getStaticPathImage('shop/banner-mobile.png')})`
                : `url(${getStaticPathImage('shop/banner.png')})`
            }
            backgroundPosition={'center'}
            backgroundSize={'cover'}
          >
            <Box
              display={'flex'}
              alignItems={isMobile ? 'flex-start' : 'center'}
              justifyContent={'space-between'}
              flexDirection={isMobile ? 'column' : 'row'}
              gap={'15px'}
            >
              <Box letterSpacing={'.7px'}>
                <Typography
                  color={{ commonType: EColor.WHITE, intensity: EColor.R900 }}
                  fontWeight={EFontWeight.MEDIUM}
                >
                  {tWidget('investmentAssistantBanner.title')}
                </Typography>
                <Typography
                  color={{ commonType: EColor.WHITE, intensity: EColor.R900 }}
                  fontWeight={EFontWeight.MEDIUM}
                >
                  {tWidget('investmentAssistantBanner.subtitle')}
                </Typography>
              </Box>
              {!isMobile && (
                <Box height={'100%'} width="150px" position={'relative'}>
                  <ImageMetals isHover={isHover} />
                </Box>
              )}
              <Box>
                <Button
                  size={ESize.MD}
                  color={isHover ? EColor.SECONDARY : EColor.ACCENT}
                  endIcon={isPhone ? undefined : EIcon.CIRCLE_ARROW}
                >
                  {tWidget('investmentAssistantBanner.button')}
                </Button>
                {isMobile && (
                  <Box
                    height={'100%'}
                    width="150px"
                    position={'absolute'}
                    right={'0'}
                    bottom={'-120px'}
                  >
                    <ImageMetals isHover={isHover} />
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Paper>
      </LinkTranslatedStyledAsLink>
    </Box>
  );
};
